export default [
	// {
	// 	key: "id",
	// 	label: "#",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "name",
		label: "Nombre",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "subcategories",
		label: "Sub categorias",
		visible: true,
		thClass: "text-center",
		tdClass: "text-center",
	},

	{
		key: "created_by",
		label: "Creado por",
		visible: true,
		thClass: "text-center",
	},
	// {
	// 	key: "created_at",
	// 	label: "Creado por",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "actions",
		label: "Acciones",
		thClass: "text-center",
		visible: true,
	},
]
