<template>
	<b-modal
		ref="modal-subcategory-detail"
		:title="getTitle"
		@hidden="$emit('close')"
		size="xmd"
		no-close-on-backdrop
		no-close-on-esc
	>
		<b-card no-body class="p-1">
			<h4>Sub categorias:</h4>
		</b-card>
		<!-- <b-card no-body> -->
			<SubCategoryTable :category_id="info.id" ref="subcategory-table"></SubCategoryTable>
		<!-- </b-card> -->

		<template #modal-footer>
			<div class="d-flex justify-content-end">
				<b-button variant="danger" @click="$emit('close')">Cerrar</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import SubCategoryTable from "@/views/brain/administrative/views/sub-categories/components/SubCategoryTable.vue"
import CategoriesService from "@/views/brain/administrative/views/categories/services/categories.service.js"
import SubCategoriesService from "@/views/brain/administrative/views/sub-categories/services/sub-categories.service.js"

export default {
	components: { SubCategoryTable },
	props: {
		info: { type: Object, required: true },
	},
	mixins: [modalMixin],
	data() {
		return {}
	},
	async mounted() {
		this.toggleModal("modal-subcategory-detail")
	},
	async created() {
		// await this.getCategories()
	},
	computed: {
		getTitle() {
			return `Categoria: ${this.info.name}`
		},
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
	},
}
</script>
