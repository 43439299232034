<template>
	<div>
		<b-card no-body class="p-1">
			<div class="mb-2">
				<!-- Paginator -->
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page ? start_page : 0 }} a {{ to_page ? to_page : 0 }} de
								{{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="reset" />
					</b-col>

					<!-- Search -->
					<b-col cols="12" xl="8" class="mt-1">
						<div class="container-btns-page-specimens d-flex justify-content-end">
							<div class="d-flex">
								<b-form-input
									v-model="search_input"
									class="d-inline-block mr-1"
									placeholder="Buscar categoria..."
									debounce="500"
									@keyup.enter="$refs['table-Categories'].refresh()"
								/>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="fields"
					sticky-header="40vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-Categories"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(id)="data">
						<div class="d-flex d-code">
							<span>{{ data.index + 1 }}</span>
						</div>
					</template>
					<template #cell(name)="data">
						<div class="d-flex d-code">
							<span>{{ data.item.name }}</span>
						</div>
					</template>
					<template #cell(subcategories)="data">
						<div class="d-flex d-code">
							<h4
								v-if="data.item.subcategories > 0"
								class="cursor-pointer"
								@click="openModalSub(data.item)"
							>
								<b-badge variant="light-primary">
									{{ data.item.subcategories }}
								</b-badge>
							</h4>
							<h4 v-else>
								<b-badge variant="light-dark">
									{{ data.item.subcategories }}
								</b-badge>
							</h4>
						</div>
					</template>
					<template #cell(created_by)="data">
						<div class="d-flex d-code created">
							<span>{{ data.item.created_by }}</span>
							<span>{{ data.item.created_at | myGlobalDayWithHour }}</span>
						</div>
					</template>
					<!-- <template #cell(created_at)="data">
						<div class="d-flex d-code">
						</div>
					</template> -->
					<template #cell(actions)="data">
						<div class="d-flex d-code">
							<b-button variant="warning" class="btn-icon ml-1 btn-sm" @click="openEditModal(data.item)">
								<feather-icon icon="EditIcon" />
							</b-button>
							<b-button
								variant="danger"
								class="btn-icon ml-1 btn-sm"
								@click="deleteCategories(data.item.id, data.item.name)"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</div>
		</b-card>
		<!-- {{ form }} -->
		<CategoriesModal
			v-if="openModalCategorie"
			@close="openModalCategorie = false"
			@registered="reset(), (openModalCategorie = false)"
			:info="form"
		/>

		<SubCatDetailModal v-if="showSub" :info="infoCat" @close="showSub = false" />
	</div>
</template>

<script>
import Fields from "@/views/brain/administrative/views/categories/data/fields-data.js"
import ServiceCategories from "@/views/brain/administrative/views/categories/services/categories.service.js"
import CategoriesModal from "@/views/brain/administrative/views/categories/components/CategoriesModal.vue"
import SubCatDetailModal from "@/views/brain/administrative/views/categories/components/SubCatDetailModal.vue"

// import TableCategories from "@/views/brain/administrative/views/categories/components/TableCategories.vue"
export default {
	components: {
		CategoriesModal,
		SubCatDetailModal,
	},

	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			fields: Fields,
			search_input: "",
			openModalCategorie: false,
			form: {
				id: null,
				name: null,
			},
			infoCat: null,
			showSub: false,
		}
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input,
				page: ctx.currentPage,
				perpage: ctx.perPage,
			}
			try {
				const { data } = await ServiceCategories.getCategories(params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		openModalSub(item) {
			this.infoCat = item
			this.showSub = true
		},

		async deleteCategories(id, name) {
			const confirm = await this.showConfirmSwal({
				text: `Eliminar la categoria ${name}`,
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await ServiceCategories.deleteCategories(id)
				this.showSuccessToast(deleted.message)
				this.reset()
			} catch (error) {
				this.showErrorSwal(s, error.response?.data)
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		refreshTable() {
			this.$refs["table-Categories"].refresh()
		},

		createCategories() {
			this.form.id = null
			this.form.name = null
			this.openModalCategorie = true
		},

		openEditModal(data) {
			this.form.id = data.id
			this.form.name = data.name

			this.openModalCategorie = true
		},
		reset() {
			this.$refs["table-Categories"].refresh()
		},
	},
}
</script>

<style scoped>
.d-code {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}

.created {
	flex-direction: column;
	gap: 0.5rem;
}
</style>
